(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/raffle-information.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/raffle-information.js');
"use strict";


const formatCurrency = svs.utils.format.Currency;
const {
  Card,
  CardBody
} = svs.ui.ReactCard;
const {
  onReadMoreFinishedClick,
  onReadMoreOngoingClick
} = svs.supportern.utils.tracking;
const {
  STATUS
} = svs.supportern.utils.tools;
const {
  useAtomValue
} = jotai;
const {
  drawAtom
} = svs.supportern.atoms;
const RaffleInformation = _ref => {
  let {
    className = '',
    isSettled
  } = _ref;
  const draw = useAtomValue(drawAtom);
  const {
    status,
    results: {
      stats: {
        totalRows,
        totalPlayers,
        poolSize
      }
    },
    ownerTeam: {
      name
    }
  } = draw;
  const {
    amount
  } = poolSize !== null && poolSize !== void 0 ? poolSize : {};
  isSettled = isSettled !== null && isSettled !== void 0 ? isSettled : status === STATUS.FINISHED;
  if (!amount) {
    return null;
  }
  const handleExpandClick = () => {
    if (status === STATUS.ACTIVE) {
      onReadMoreOngoingClick();
    } else {
      onReadMoreFinishedClick();
    }
  };
  return React.createElement("div", {
    className: "margin-top-2 margin-bottom-1 ".concat(className),
    onClick: handleExpandClick
  }, React.createElement(Card, {
    className: "supportern-raffle-information"
  }, React.createElement(CardBody, null, React.createElement("div", {
    className: "f-center"
  }, React.createElement("span", {
    className: "block f-bold"
  }, name), React.createElement("span", {
    className: "block"
  }, isSettled ? 'fick:' : 'kommer att få minst:'), React.createElement("span", {
    className: "block f-700 f-bold"
  }, formatCurrency(amount), ' ', "kr"), React.createElement("br", null)), React.createElement("div", {
    className: "f-center"
  }, React.createElement("p", null, "G\xF6r som", ' ', React.createElement("span", {
    className: "f-bold"
  }, totalPlayers), ' ', "andra och st\xF6tta", ' ', React.createElement("span", {
    className: "f-bold"
  }, name), "."), React.createElement("p", null, React.createElement("span", {
    className: "f-bold"
  }, formatCurrency(totalRows)), ' ', "spel s\xE5lda.")))));
};
setGlobal('svs.supportern.components.RaffleInformation', RaffleInformation);

 })(window);